import React from 'react'
import Button from '../components/Button'
import MainInner from '../components/MainInner'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import { Input } from '../components/Inputs'
import { bs } from '../shevy'
import { useAuthContext } from '../contexts/AuthContext'

export default function ForgotPassword() {
  const [, { sendResetPasswordEmail }] = useAuthContext()
  const [state, setState] = React.useState('IDLE')
  const [email, setEmail] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState(null)

  const handleEmailChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setEmail(e.target.value)
  }, [])

  const handleSubmit = React.useCallback(
    async e => {
      e.preventDefault()

      setState('PENDING')

      try {
        const { error } = await sendResetPasswordEmail(email)

        if (error) {
          setErrorMessage(error.message)
          setState('FAILURE')
          return
        }

        setState('SUCCESS')
      } catch (error) {
        setErrorMessage(error.message)
        setState('FAILURE')
      }
    },
    [email, sendResetPasswordEmail]
  )

  return (
    <>
      <Seo title="Forgot Password" />
      <MainInner>
        <h2>Forgot Password</h2>
        <p>
          Provide the email address for your account and we will send you a
          reset password email.
        </p>
        <div css={{ backgroundColor: 'var(--colors-offset)', padding: bs() }}>
          {state === 'FAILURE' && (
            <div>
              <p>Error: {errorMessage}</p>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Spacer bottom={1}>
              <Input label="Email" onChange={handleEmailChange} value={email} />
            </Spacer>

            <Button
              disabled={state === 'PENDING'}
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </form>
          {state === 'SUCCESS' && <Spacer top={1}>Success!</Spacer>}
        </div>
      </MainInner>
    </>
  )
}
